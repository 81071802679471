@font-face {
  font-family: "neonoir";
  src: url(https://use.typekit.net/azi1ygf.css);
}
body {
  margin: 0;
  height: 100%;
}
.App {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: none;
}

.NeonHeader {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #f09,
    0 0 82px #f09, 0 0 92px #f09, 0 0 102px #f09, 0 0 151px #f09;
  transform: skew(-12deg, -15deg);
  white-space: pre-wrap;
}

h1,
h2 {
  text-align: center;
  font-weight: 400;
  line-height: 1;
}

h1 {
  font-size: 6.2rem;
  font-family: neonoir, sans-serif;
  font-weight: 700;
  font-style: normal;
  animation: pulsate 0.11s ease-in-out infinite alternate;
}

h2 {
  font-size: 1.8rem;
}

.resumeIframe{
    width:60.934rem;
    height: 45rem;
    border: 1px solid #777;
}

.svgLogoWhite {
  stroke-width: 1em;
  stroke: #fff;
  filter: invert(10%);
  height: 4em;
}
.svgLogoBlack {
  stroke-width: 0.2em;
  stroke: #fff;
  filter: invert(100%);
  height: 2.6em;
}
@keyframes pulsate {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #f09,
      0 0 80px #f09, 0 0 90px #f09, 0 0 100px #f09, 0 0 150px #f09;
  }

  0% {
    text-shadow: 0 0 4px #fff, 0 0 10px #fff, 0 0 18px #fff, 0 0 38px #f09,
      0 0 73px #f09, 0 0 80px #f09, 0 0 94px #f09, 0 0 140px #f09;
  }
}
@media (max-width: 600px) {
    h1 {
        font-size: 5.8rem;
        margin-inline-start: auto;
    }
}
